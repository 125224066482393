import {
  documentToHtmlString
} from '@contentful/rich-text-html-renderer'

import _isEmpty from 'underscore/cjs/isEmpty.js'

import {
  isObject
} from '~/helpers/functions'

export const toHtml = str => {
  return documentToHtmlString(str)
}

export const removeHtmlString = str => {
  return str.replace(/<(.|\n)*?>/g, '').replace(/&amp;/g, '&')
}

export const generateExcerpt = (str, maxLength = 120) => {
  return str.substring(0, maxLength) + (str.length > maxLength ? '...' : '')
}

export const generateNewsObjectFromContentful = (object, extendObj) => {
  const _obj = JSON.parse(JSON.stringify(object))

  let titleRendered = _obj.title
  if (_obj?.titleRichText) {
    const titleRichTextParse = toHtml(_obj.titleRichText.json)

    if (removeHtmlString(titleRichTextParse) !== '') {
      titleRendered = removeHtmlString(titleRichTextParse).replace(/&amp;/g, '&')
    }
  }

  _obj.mainContent.json.content = _obj.mainContent.json.content.filter(item => !item.nodeType.includes('heading'))

  let content = _obj?.mainContent ? generateExcerpt(removeHtmlString(toHtml(_obj.mainContent.json))) : ''

  if (_obj?.excerpt) {
    content = _obj.excerpt
  }

  return {
    title: titleRendered,
    tag: _obj.vertical,
    content,
    authorName: _obj?.author
      ? _obj.author.name
      : 'Compare Club',
    url: _obj.slug,
    authorUrl: _obj?.author
      ? _obj.author.authorUrl
      : '/',
    image: _obj?.featureImage
      ? _obj.featureImage.url
      : FEATURED_IMAGE_DEFAULT_IMAGE,
    ...extendObj
  }
}

export const removeRichTextInPosts = (object, extendObj) => {
  const title = (() => {
    const temp = object?.title

    if (object?.titleRichText) {
      const parsedTitle = toHtml(object?.titleRichText.json)

      if (removeHtmlString(parsedTitle)) {
        return removeHtmlString(parsedTitle).replace(/&amp;/g, '&')
      }
    }

    return temp
  })()

  const content = (() => {
    const temp = object?.mainContent
      ? generateExcerpt(removeHtmlString(toHtml(object.mainContent.json)))
      : ''

    if (object?.excerpt) {
      return object?.excerpt
    }

    if (object?.subtitle) {
      return object?.subtitle
    }

    return temp
  })()

  return {
    title,
    tag: object.vertical,
    content,
    authorName: object?.author
      ? object.author.name
      : 'Compare Club',
    url: object.slug,
    redirectUrl: object?.redirectUrl?.slug,
    authorUrl: object?.author
      ? object.author.authorUrl
      : '/',
    image: object?.featureImage
      ? object.featureImage.url
      : FEATURED_IMAGE_DEFAULT_IMAGE,
    category: object?.category ?? null,
    ...extendObj
  }
}

export const generateArticlesObjectFromContentful = (object, extendObj) => {
  const image = object.featureImage?.url ? `${object.featureImage.url}?w=421&h=238` : 'https://asset.compareclub.com.au/content/default-featured-image.png'
  const author = object.author?.name ? object.author.name : 'Compare Club'
  const vertical = object?.vertical ? object.vertical : ''
  const featuredMenuItem = object?.featuredMenuItem ? object.featuredMenuItem : ''
  return {
    title: typeof object.title !== 'undefined' ? object.title : 'title',
    content: typeof object.subTitle !== 'undefined' ? object.subTitle : 'sub title',
    image,
    author,
    vertical,
    featuredMenuItem,
    date: typeof object.date === 'undefined' ? object.sys.createdAt : object.date,
    url: typeof object.slug !== 'undefined' ? object.slug : '/',
    ...extendObj
  }
}

/*
 * check whether string is valid url
 * @param urlString {String}
 * @returns {true|false}
 */
export const isValidUrl = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' +
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
  '((\\d{1,3}\\.){3}\\d{1,3}))' +
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
  '(\\?[;&a-z\\d%_.~+=-]*)?' +
  '(\\#[-a-z\\d_]*)?$', 'i')
  return !!urlPattern.test(urlString)
}

export const handleRequest = (responses, key) => {
  return {
    networkStates: responses.networkStates,
    data: responses.data[key]
  }
}

export const handleRequestSingle = (responses, key) => {
  return {
    networkStates: responses.networkStates,
    data: responses.data[key].total > 0
      ? responses.data[key].items[0]
      : {}
  }
}

/**
 * Returns the value of key in the map
 * if the key doesn't exist it returns "default" key value or undefined
 * @param key {String}
 * @param obj {Object}
 */
export const getKey = (key, obj) => obj[key] === undefined ? obj.default : obj[key]

/**
 * Transfroms a string to an array
 * if the key doesn't exist it returns "default" key value or undefined
 * @param str {String}
 */
export const toArray = str => Array.isArray(str) ? str : [str]

export const generatePostStructure = ({ items, hasFeature = false } = {}) => {
  if (_isEmpty(items)) {
    return {
      items: []
    }
  }

  const featured = (() => {
    if (!hasFeature) {
      return null
    }

    return items.length > 1
      ? removeRichTextInPosts(items[0], { exist: true })
      : null
  })()

  const tempList = featured
    ? items.slice(1, items.length)
    : items

  const list = !isObject(tempList)
    ? tempList.map(item => removeRichTextInPosts(item, {
      layout: 'vertical'
    }))
    : removeRichTextInPosts(tempList, { 
      layout: 'vertical'
    })

  return {
    featured,
    items: list
  }
}

/*
 * transforms string to Snake Case
 * @param str {String}
 */
export const strToSnakeCase = str => {
  if (!str) {
    return str
  }

  return str
    .replace(/\s/g, '_')
    .replace(/[^a-z|0-9]/gi, '_')
    .replace(/\B([A-Z])/g, '_$1')
    .toLowerCase()
    .replace(/__/, '_')
}